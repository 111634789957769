import React, { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import io from "socket.io-client";
import Backdrop from "@mui/material/Backdrop";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

function App() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wait, setWait] = useState(false);
  const [clickedId, setClickedId] = useState(null);
  const [sys, setSys] = useState("null");

  // const server = "https://ded684b7-9b16-4724-b86b-ba5c6919ae45-00-3o8bc11aurwbk.riker.replit.dev/";
  const server = "https://council-server-sv1.hnawny.in.th/";

  useEffect(() => {
    // const socket = io('https://council-server.hnawny.in.th/');
    const socket = io(server);

    socket.on("connect", () => {
      // เมื่อเชื่อมต่อสำเร็จ
      console.log("เชื่อมต่อกับเซิร์ฟเวอร์สำเร็จ");
    });
    socket.on("disconnect", () => {
      // เมื่อหลุดการเชื่อมต่อ
      console.log("ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้");
    });

    const fetchDataAndUpdateLeaderboard = () => {
      socket.emit("leaderboardData");

      socket.on("leaderboardData", (data) => {
        const sortedData = data.sort((a, b) => b.score - a.score); // เรียงลำดับข้อมูลตามคะแนนมากสุด
        setLeaderboardData(sortedData);
        data.forEach((item, index) => {
          let change = "none";
          if (index < 3) {
            // สำหรับรายการ 2 รายการแรกให้ตรวจสอบว่ามีคะแนนมากกว่าหรือเท่ากับคะแนนของรายการก่อนหน้าหรือไม่
            change = "up";
          } else if (index >= data.length - 2) {
            // สำหรับรายการที่เหลือให้ตรวจสอบว่ามีคะแนนน้อยกว่าคะแนนของรายการก่อนหน้าหรือไม่
            change = "down";
          }
          item.change = change;
        });

        setTimeout(() => {
          setLoading(false);
        }, 1500);
      });
    };

    fetchDataAndUpdateLeaderboard();

    const interval = setInterval(fetchDataAndUpdateLeaderboard, 5000);

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, []);

  // เพิ่่มคะแนน
  const articleClicked = ({ id }) => {
    setWait(true);
    setClickedId(id);
    setSelectedProfile(null);
    const socket = io(server);
    setTimeout(() => {
      socket.emit("articleClicked", id);
      setWait(false);
    }, 300);
  };
  //ลบคะแนน
  const reduceClicked = ({ id }) => {
    setWait(true);
    setClickedId(id);
    setSelectedProfile(null);
    const socket = io(server);
    setTimeout(() => {
      socket.emit("reduceClicked", id);
      setWait(false);
    }, 300);
  };

  const [selectedProfile, setSelectedProfile] = useState(null);
  const handleOnClick = (id) => {
    // Check if "token" exists in local storage
    const token = localStorage.getItem("token");
    if (token === "Hnawny") {
      const clickedProfile = leaderboardData.find(
        (profile) => profile.id === id
      );
      setSelectedProfile(clickedProfile);
    }
  };

  useEffect(() => {
    const socket = io(server);
    setLoading(true);
    socket.on("sys", (data) => {
      // console.log('data:', data['0']['status']);
      setLoading(false);
      setSys(data);
    });
  }, []);

  const Refu = () => {
    const socket = io(server);
    setWait(true);
    socket.emit("fetchLeaderboardData");
    setTimeout(() => {
      setWait(false);
    }, 300);
  };

  const numberWithCommas = (number) => {
    return number.toLocaleString();
  };

  const [isHidden, setIsHidden] = useState(false);

  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={6}>
        <article className="leaderboard">
          <header>
            <i
              className="bi bi-award leaderboard__icon"
              style={{ color: "#ffffff", fontSize: 40, textAlign: "center" }}
            ></i>
            <h1 className="leaderboard__title">
              <span className="leaderboard__title--top">
                {sys["0"]["message_1"]}
              </span>
              <span className="leaderboard__title--bottom">Leaderboard</span>
            </h1>
          </header>
          {sys && sys["0"]["status"] === "off" ? (
            <>
            <Backdrop 
              sx={{ 
                backgroundImage: "url('/1.gif')", 
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: (theme) => theme.zIndex.drawer + 1 
              }} 
              open={true}
            >
              <span className="leaderboard__ac">
                <h1 style={{ textAlign: "center" }}>ระบบปิดการโหวตโพลแล้ว</h1>
                <h1><i className="bi bi-calendar-week"></i>&nbsp;{sys["0"]["message"]}</h1>
              </span>
            </Backdrop>

              <Box>
                <img
                  style={{ alignItems: "center" }}
                  src="91dd0e00-1624-4eb6-8305-945efe23170a-IMG_3580.jpg"
                />
                <h1 style={{ textAlign: "center" }}>ระบบปิดการโหวตโพลแล้ว</h1>
                <h5 style={{ textAlign: "center", padding: "50" }}>
                  {sys["0"]["message"]}
                </h5>
                <Button variant="contained" fullWidth onClick={Refu}>
                  รีเฟรช
                </Button>
              </Box>
            </>
          ) : (
            <>
              <h5></h5>
              <Stack spacing={2} direction="row">
                <Grid item xs={12} p={2} style={{ textAlign: "center" }}>
                  <Button variant="contained" fullWidth onClick={Refu}>
                    รีเฟรช
                  </Button>{" "}
                </Grid>
              </Stack>

              <div className="app">
                <div className={`iframe ${isHidden ? "hidden" : ""}`}>
                  <iframe
                    id="ytplayer"
                    type="text/html"
                    width="410"
                    height="300"
                    src={sys["0"]["iframe"]}
                    frameBorder="0"
                    // allowFullScreen
                  /><br/><br/><br/><br/>
                  <Button
                    className="toggle-button"
                    variant="contained"
                    color="success"
                    onClick={toggleHidden}
                  >
                    {isHidden
                      ? "👇🏻"
                      : "❌"}
                  </Button>
                </div>
              </div>
              <main className="leaderboard__profiles">
                {leaderboardData.map((profile, index) => (
                  <article
                    className={`leaderboard__profile ${
                      clickedId === profile.id ? "slide-up-animation" : ""
                    }`}
                    key={index}
                    onClick={() => handleOnClick(profile.id)}
                  >
                    <img
                      src={profile.img}
                      alt={profile.name}
                      style={
                        profile.score === leaderboardData[0].score
                          ? { backgroundImage: `url(/f2.gif)` }
                          : {}
                      }
                      className="leaderboard__picture"
                    />
                    <span className="leaderboard__name">
                      <br />
                      {profile.name}
                      <br />
                      <span
                        className={`leaderboard__value`}
                        data-score={profile.score}
                      >
                        {numberWithCommas(profile.score)}&nbsp;
                        {profile.change === "up" && (
                          <i className="bi bi-arrow-up-circle"></i>
                        )}
                        {profile.change === "down" && (
                          <i className="bi bi-arrow-down-circle"></i>
                        )}
                        {profile.change === "none" && (
                          <i class="bi bi-arrows-expand"></i>
                        )}
                      </span>
                      <br />
                    </span>
                  </article>
                ))}
                <span className="leaderboard__w">
                  เมื่อมาถึงวันเลือกตั้งจริง
                  คะแนนที่นี่จะถูกรีเซ็ตและจะแสดงคะแนนที่กำลังเปิดในขณะนั้น
                  และไม่สามารถกดได้ในขณะนั้นๆ
                </span>
              </main>
            </>
          )}
        </article>
        <Backdrop
          sx={{ backgroundColor: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={wait || loading}
        >
          <span className="leaderboard__ac">
            <img src="/image-removebg-preview (1).png" width={150} /><br/><br/>
            <h1> <i className="bi bi-hourglass-split"></i>&nbsp;โปรดรอสักครู่...</h1>
          </span>
        </Backdrop>

        <Dialog
          open={!!selectedProfile}
          onClose={() => setSelectedProfile(null)}
        >
          <DialogContent>
            {selectedProfile && (
              <>
                <DialogTitle id="alert-dialog-title">
                  จะโหวตในด้านไหนของ {selectedProfile.name}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        onClick={() =>
                          articleClicked({
                            id: selectedProfile.id,
                          })
                        }
                      >
                        เพิ่มคะแนน
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        size="large"
                        onClick={() =>
                          reduceClicked({
                            id: selectedProfile.id,
                          })
                        }
                      >
                        ลบคะแนน
                      </Button>
                    </Stack>
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default App;
